import React, { useEffect, useState } from 'react';

import { graphql } from 'gatsby';

import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import IntroPage from '../../../../components/ContentType/IntroPage/IntroPage';
import TextVisuelTwoCol from '../../../../components/ContentType/TextVisuelTwoCol/TextVisuelTwoCol';
import PageBanner from '../../../../components/PageBanner/PageBanner';
import ScrollPageNav from '../../../../components/ScrollPageNav/ScrollPageNav';
import Seo from '../../../../components/Seo/Seo';
import Layout from '../../../../Layout';
import DocumentView from '../../../../Molecules/DocumentView/DocumentView';
import ShapeBackground from '../../../../Molecules/ShapeBackground/ShapeBackground';
import TitlePage from '../../../../Molecules/TitlePage/TitlePage';
import TitleSection from '../../../../Molecules/TitleSection/TitleSection';
import { getImage, getBreadCrumb, getDocument, formatBytes } from '../../../../Utils/Utils';

import '../engagements.scss';
let classNames = require('classnames');

const PrincipauxEnjeux = ({ data }) => {
  const imagesArray = data?.allImages?.edges;
  const documentsArray = data.allDocuments.edges;
  const pageData = data?.pageData?.edges[0].node;
  const allMenu = data.allMenu.edges;
  const breadCrumb = getBreadCrumb(allMenu, pageData.path?.alias);

  const [innerNavList, setInnerNavList] = useState([]);

  let sectionArr = [];
  let leftRightBlocks = [];
  let sectionCount = 0;

  useEffect(() => {
    setInnerNavList(sectionArr);
  }, []);

  return (
    <Layout imagesArray={imagesArray}>
      <Seo title={pageData.title} description={pageData.field_sous_titre} />
      <div
        className={classNames(
          'page_template',
          'principaux_enjeux'
        )}
      >
        <ScrollPageNav
          data={innerNavList}
        />
        <PageBanner visuel={getImage(imagesArray, pageData?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp?.fluid} scroll={true}>
          <div className="wrapper_page">
            <Breadcrumb
              page_banner={true}
              data={{
                //gparent: { title: breadCrumb?.grandParent?.name, url: breadCrumb?.grandParent?.link },
                parentPage: { title: breadCrumb?.parent?.name, url: breadCrumb?.parent?.link },
                currentPage: { title: breadCrumb?.current?.name, url: breadCrumb?.current?.link },
                locale: pageData.langcode
              }}
            />
            <TitlePage
              color="color_white"
              title={pageData.title}
              description={pageData.field_sous_titre}
            />
          </div>
        </PageBanner>

        {pageData.relationships?.field_blocs?.map((block, i) => {
          if (block.field_title?.processed) {
            sectionCount++;
            sectionArr.push({
              title: '0' + sectionCount + '. ' + block.field_title?.processed,
              section: 'scroll_to_' + sectionCount
            });
          }

          switch (block.__typename) {
            case 'block_content__block_texte':
              leftRightBlocks = [];
              block.relationships?.field_section?.forEach((item => {
                leftRightBlocks.push({
                  text: item.relationships?.field_bloc_texte[0]?.field_bloc_text_1.processed,
                  title: item.relationships?.field_bloc_texte[0]?.field_title?.processed,
                  visuel: getImage(imagesArray, item?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp,
                  arrowColor: item.relationships?.field_bloc_texte[0]?.field_theme_title,
                  imageAlignment: item.field_alignment,
                  imageNoPadding: item.field_no_padding,
                  field_backgroud_color: block.field_backgroud_color
                });
              }));
              return (

                block.relationships?.field_section?.length > 0 && <section className={classNames('section_content', 'scroll_to_' + sectionCount)}>
                  <ShapeBackground
                    top={block.field_backgroud_color === 'ice' ? 'left' : block.field_border_top && 'left'}
                    color={block.field_backgroud_color === 'ice' ? 'catskill_white' : (block.field_backgroud_color === 'navy' ? 'dark_bleu z-index-top' : 'white')}
                    bottomInfinite={block.field_infinite_border}
                    bottom={block.field_border_bottom_display}
                  >
                    <div className="wrapper_page_xs">
                      <TitleSection title={block.field_title?.processed} type="arrow" />

                      {leftRightBlocks.map((secBlock, i) => (
                        <TextVisuelTwoCol
                          key={i}
                          title={secBlock.title}
                          extremeLeft={(secBlock?.imageNoPadding && secBlock?.imageAlignment === 'left') ? true : false}
                          extremeRight={(secBlock?.imageNoPadding && secBlock?.imageAlignment === 'left') ? false : true}
                          orderInverse={secBlock?.imageAlignment === 'right' ? true : false}
                          visuel={secBlock.visuel}
                          extactHalf={secBlock?.imageAlignment === 'left' ? true : false}
                          //alignLeft={secBlock?.imageAlignment === 'left' ? true : false}
                          alignRight={secBlock?.field_alignment === 'right' ? true : false}
                          alignTop={true}
                          text={secBlock.text}
                          colorTitle={secBlock.field_backgroud_color === 'navy' ? 'color_white' : undefined}
                          colorDescription={secBlock.field_backgroud_color === 'navy' && 'color_white'}
                        />
                      ))}
                    </div>
                  </ShapeBackground>

                </section>

              );

            default:
              break;
          }
        })}
      </div>
    </Layout >
  );
};

export const query = graphql`
query enJeuxTemplateQuery($locale: String!, $slug: String!, $imagesID: [Int!], $documentsID: [Int!]) {
  pageData: allNodePage(
    filter: {langcode: {eq: $locale}, path: {alias: {eq: $slug}}}
  ) {
    edges {
      node {
        title
        langcode
        field_type
        field_padding
        field_sous_titre
        path {
          alias
          langcode
        }
        relationships {
          field_image {
            drupal_internal__mid
          }
          field_blocs {
            __typename
            ... on Node {
              ... on block_content__block_texte {
                id
                field_title {
                  processed
                }
                body {
                  processed
                }
                field_subtitle {
                  processed
                }
                field_backgroud_color
                field_infinite_border
                field_border_top
                field_border_bottom_display
                relationships {
                  field_image {
                    drupal_internal__mid
                  }
                  field_section {
                    field_no_padding
                    field_alignment
                    relationships {
                      field_image {
                        drupal_internal__mid
                      }
                      field_bloc_texte {
                        field_theme_title
                        field_title {
                          processed
                        }
                        field_bloc_text_1 {
                          processed
                        }
                      }
                    }
                  }
                }
              }
              ... on block_content__block_document {
                relationships {
                  field_file {
                    drupal_internal__mid
                  }
                }
              }
            }
          }
        }
        body {
          processed
        }
      }
    }
  }

  allDocuments: allMediaDocument(filter: {langcode: {eq: "fr"}, drupal_internal__mid: {in: $documentsID}}) {
    edges {
      node {
        langcode
        field_media_document {
          description
        }
        relationships {
          field_media_document {
            localFile {
              publicURL
              size
              name
            }
          }
        }
        drupal_internal__mid
      }
    }
  }

  allImages: allMediaImage(
    filter: {langcode: {eq: "fr"}, drupal_internal__mid: {in: $imagesID}}
  ) {
    edges {
      node {
        drupal_internal__mid
        relationships {
          field_media_image {
            localFile {
              publicURL
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
                mobile: fixed(width: 456, height: 456) {
                  ...GatsbyImageSharpFixed
                }
                desktop: fixed(width: 1256, height: 814) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        langcode
      }
    }
  }

  allMenu :   allMenuLinkContentMenuLinkContent(
    filter: {langcode: {eq: $locale}}
  ) {
    edges {
      node {
        drupal_parent_menu_item
        drupal_id
        langcode
        link {
          url
        }
        menu_name
        title
      }
    }
  }
}
`;

export default PrincipauxEnjeux;
